<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row v-if="canEdit && form.value.length < 1">
      <b-col cols="5">
        <div class="form-group mb-0">
          <div class="input-eye">
            <p class="tag-eye">
              <EyeFill />
              D
            </p>
            <input
              autocomplete="off"
              id="olhoDireitoPressaoIntraocularCorrigida"
              :value="fields.olhoDireito"
              :disabled="!canEdit"
              @input="el => debounceInput('olhoDireito', el.target.value, 0)"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="5">
        <div class="form-group mb-0">
          <div class="input-eye">
            <p class="tag-eye">
              <EyeFill />
              E
            </p>
            <input
              autocomplete="off"
              id="olhoEsquerdoPressaoIntraocularCorrigida"
              :value="fields.olhoEsquerdo"
              :disabled="!canEdit"
              @input="el => debounceInput('olhoEsquerdo', el.target.value, 0)"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <b-row class="padding-row">
          <button
            class="btn blue-underline"
            @click="copyEyeValues(0)"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <Copy class="icon" />
          </button>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-for="(value, index) of form.value" :key="index">
      <b-col cols="5">
        <div class="form-group mb-0">
          <div class="input-eye">
            <p class="tag-eye">
              <EyeFill />
              D
            </p>
            <input
              autocomplete="off"
              id="olhoDireitoPressaoIntraocularCorrigida"
              :value="value.olhoDireito"
              :disabled="!canEdit"
              @input="el => debounceInput('olhoDireito', el.target.value, index)"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="5">
        <div class="form-group mb-0">
          <div class="input-eye">
            <p class="tag-eye">
              <EyeFill />
              E
            </p>
            <input
              autocomplete="off"
              id="olhoEsquerdoPressaoIntraocularCorrigida"
              :value="value.olhoEsquerdo"
              :disabled="!canEdit"
              @input="el => debounceInput('olhoEsquerdo', el.target.value, index)"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <b-row class="padding-row">
          <button
            class="btn blue-underline"
            @click="copyEyeValues(index)"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <Copy class="icon" />
          </button>
          <div v-if="canEdit" class="new-medical-report">
            <Icon tooltip="Deletar" v-if="index > 0">
              <Trash class="icon trash" @click="removeFields(field)" />
            </Icon>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <Icon tooltip="Adicionar novo campo">
          <button
            class="btn blue-underline button-copy"
            @click="addNewField()"
          >
            <Plus class="icon" />
            Adicionar novo campo
          </button>
        </Icon>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'PressaoIntraocularCorrigidaForm',
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Icon: () => import('@/components/General/Icon')
  },
  data() {
    return {
      debounceInput: debounce(this.updateForm, 300),
      fields: {
        olhoDireito: null,
        olhoEsquerdo: null
      }
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.pressaoIntraocularCorrigida
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/pressaoIntraocularCorrigida', ['handleFields', 'addValue', 'handleProps']),
    updateForm(key, value, index) {
      if(!this.form.value.length) {
       this.form.value.push(this.fields)
      }
      this.form.value[index][key] = value
      this.handleProps({ key: 'value', value: this.form.value })
      this.updateMedicalRecord(this.form)
    },
    hideForm() {
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.pressaoIntraocularCorrigida = null
    },
    addNewField() {
      const field = { olhoDireito: null, olhoEsquerdo: null }
      this.addValue(field)
      this.updateMedicalRecord(this.form)
    },
    copyEyeValues(index) {
      this.updateForm('olhoEsquerdo', this.form.value[index].olhoDireito, index)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    removeFields(fields) {
      const index = this.form.value.indexOf(fields)
      this.form.value.splice(index, 1)
      this.updateMedicalRecord(this.form)
    },
  }
}
</script>

<style lang="scss" scoped>

.form-group {

  font-weight: 600;
  font-size: 14px;
  color: var(--blue-700);
  margin-bottom: 4px !important;
}

.padding-row {
  padding-right: 8px !important;
}

.new-medical-report {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  gap: 10px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}
.blue-underline {
  text-decoration: underline;
  color: var(--blue-500);
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--blue-500);
  margin: 4px 12px 0px 4px;
  cursor: pointer;
}
</style>
